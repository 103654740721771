<template>
  <v-dialog v-model="dialogActive" width="800" persistent>
    <v-card>
      <v-card-title>Editing: {{ inputVal.label }}</v-card-title>
      <v-tabs v-model="selectedTab">
        <v-tab>Settings</v-tab>
        <v-tab>Visibility Rules</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select v-model="inputVal.input" :items="inputTypes" label="Input Type"></v-select>
                </v-col>
                <v-col>
                  <v-select v-model="inputVal.lg" :items="widthOptions" label="Input Width" item-value="lg" return-object></v-select>
                </v-col>
                <v-col>
                  <v-checkbox v-if="inputVal.input !== 'repeatable-block'" v-model="inputVal.required" label="Field is Required" hide-details></v-checkbox>
                  <v-checkbox v-else v-model="inputVal.settings.showLabel" label="Show label on block" hide-details></v-checkbox>
                </v-col>
                <v-col v-if="uniqueEnabled">
                  <v-checkbox v-model="inputVal.unique" label="Unique" hide-details>
                    <template v-slot:append>
                      <v-dialog width="600">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                        </template>
                        <v-card>
                          <v-card-title>Unique Fields</v-card-title>
                          <v-card-text>
                            <p>Within a repeatable block, you can mark some fields as being unique. This will enforce that the input value is unique among the different copies within a repeatable block. For example, if you have a repeatable block for phone numbers containing 2 fields, one for the phone number and another for the phone type, then the first field could be unique, allowing them to only have a phone number entered once. You could also enforce unique on the second field if you only want one number for each type (home, work, cell). Note that if you add a unique constraint on a select box, then the user can only submit as many different entries as there are options in the select box, so you may want to set a maximum limit on the repeatable block to match the number of entries they can add.</p>
                            <p>Checkboxes and switches work a little differently. If these types are marked as unique, then it will allow only one to be checked/toggled within the repeatable block. For example, if you have the phone block from above and add a checkbox for "Preferred", then any entry that is checked will automatically uncheck that field in all of the other copies.</p>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="inputVal.input === 'text'">
                <v-col>
                  <v-text-field v-model="inputVal.min" label="Min Characters" type="number" hint="Optional" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.max" label="Max Characters" type="number" hint="Optional" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.text.mask" label="Input Mask" type="text" hint="Optional" persistent-hint>
                    <template v-slot:append-outer>
                      <v-dialog width="750">
                        <template v-slot:activator="{ on: dialogOn }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltipOn }">
                              <v-icon v-on="{ ...dialogOn, ...tooltipOn }" color="info">fas fa-question-circle</v-icon>
                            </template>
                            <span>Click here for details about the mask options</span>
                          </v-tooltip>
                        </template>
                        <v-card>
                          <v-card-title>Mask Details</v-card-title>
                          <v-card-text>
                            <p>The input mask allows you to make sure an input matches a single specific format. This is useful for collecting things that will always be the same format, like US phone numbers, or zip codes. Below are the options for the mask values and some examples.</p>
                            <h5>Options</h5>
                            <ul>
                              <li><b>#:</b> Numeric (0-9) - allows any single-digit from 0-9</li>
                              <li><b>X:</b> Alpha-numeric (0-9 or A-z) - any number or letter, allowing both uppercase and lowercase letters</li>
                              <li><b>S:</b> Alpha (A-z) - any character in the alphabet, allowing both uppercase and lowercase letters</li>
                              <li><b>A:</b> ALPHA (A-Z uppercase only) - any lowercase letter is automatically converted to uppercase</li>
                              <li><b>a:</b> alpha (a-z lowercase only) - any uppercase letter is automatically converted to lowercase</li>
                              <li><b>H:</b> HEX (0-9 or A-F uppercase only) - any lowercase letter is automatically converted to uppercase</li>
                              <li><b>h:</b> hex (0-9 or a-f lowercase only) - any uppercase letter is automatically converted to lowercase</li>
                            </ul>
                            <p>Any character that is not included in the list above will be added to the input automatically, allowing the user to only need to fill in the missing characters</p>
                            <h5>Examples</h5>
                            <ul>
                              <li><b>(###) ###-####</b> - US Phone Number. It will allow them to enter only numeric digits and will automatically format it as shown. You will want to set the min and max characters to 14 to ensure all digits are included (10 digits plus the two parentheses, space, and dash)</li>
                              <li><b>@########</b> - Banner ID. Requires the @ symbol first followed by 8 numeric digits. You will need to set the min and max characters both to 9 in this case, to make sure all 8 digits are included along with the @.</li>
                              <li><b>['#####','#####-####']</b> - US zip codes. This one is an advanced form as it allows two different formats, both the standard 5-digits but also the extra 4-digits.</li>
                              <li><b>hh:hh:hh:hh:hh:hh</b> - Mac Address. Will automatically convert all characters to lowercase and only allow Hexadecimal characters to be entered.</li>
                            </ul>
                            <p style="margin-top:2em">Click outside of this dialog to close it.</p>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'email'">
                <v-col>
                  <v-checkbox v-model="inputVal.email.COV" label="Require Covenant Email"></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'number'">
                <v-col>
                  <v-text-field v-model="inputVal.min" label="Min Value" type="number" hint="Optional" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.max" label="Max Value" type="number" hint="Optional" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.number.step" label="Step" type="number" min="1" hint="Optional; how much to increment when they click buttons to go up/down" persistent-hint></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'textarea'">
                <v-col>
                  <v-checkbox v-model="inputVal.textarea.word" label="Count by words (default is by character)"></v-checkbox>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.min" :label="inputVal.textarea.word ? 'Min Word Count' : 'Min Character Count'" type="number" hint="Optional" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.max" :label="inputVal.textarea.word ? 'Max Word Count' : 'Max Character Count'" type="number" hint="Optional" persistent-hint></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="inputVal.input === 'select' || inputVal.input === 'combobox' || inputVal.input === 'autocomplete'">
                <v-col>
                  <v-autocomplete v-model="inputVal.options" :items="selectOptions" :search-input.sync="optionListSearch" label="Options" hint="Required" persistent-hint>
                    <template v-slot:append-outer>
                      <select-option-editor :preselect-list-id="inputVal.options"></select-option-editor>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-checkbox v-model="inputVal.select.multiple" label="Allow Multiple Selection"></v-checkbox>
                </v-col>
                <v-col v-if="inputVal.select.multiple">
                  <v-text-field v-model="inputVal.max" label="Maximum Allowed"></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'radio'">
                <v-col>
                  <v-autocomplete v-model="inputVal.options" :items="selectOptions" :search-input.sync="optionListSearch" label="Radio Options" hint="Required" persistent-hint></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'switch'">
                <v-col>
                  <v-checkbox v-model="inputVal.switch.appendToLabel" label="Append value to label"></v-checkbox>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.switch.off" label="'Off' Value" hint="Optional; default is false" persistent-hint>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.switch.on" label="'On' Value" hint="Optional; default is false" persistent-hint></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'slider' || inputVal.input === 'rangeSlider'">
                <v-col>
                  <v-text-field v-model="inputVal.range.minLabel" label="Min Label" hint="Optional" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.min" label="Min Value" type="number" hint="Required" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.range.maxLabel" label="Max Label" hint="Optional" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.max" label="Max Value" type="number" hint="Required" persistent-hint></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'datePicker'">
                <v-col>
                  <v-select v-model="inputVal.date.min" :items="dateOptions" label="Min Date" hint="Optional" persistent-hint></v-select>
                </v-col>
                <v-col>
                  <v-select v-model="inputVal.date.max" :items="dateOptions" label="Max Date" hint="Optional" persistent-hint></v-select>
                </v-col>
                <v-col>
                  <v-checkbox v-model="inputVal.date.yearFirst" label="Year First">
                    <template v-slot:append>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                        </template>
                        <span>This option will make the calendar popup start with the year selection; great for birth date or for dates that would not be near the current date.</span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'timePicker'">
                <v-col>
                  <time-picker v-model="inputVal.time.min" :outlined="false" label="Min Time" hint="Optional" persistent-hint> </time-picker>
                </v-col>
                <v-col>
                  <time-picker v-model="inputVal.time.max" :outlined="false" label="Max Time" hint="Optional" persistent-hint></time-picker>
                </v-col>
              </v-row>
              <v-row v-else-if="inputVal.input === 'file'">
                <v-col>
                  <v-text-field v-model="inputVal.file.folder" label="Google Drive Folder ID" hint="Required" persistent-hint>
                    <template v-slot:append-outer>
                      <v-dialog width="400">
                        <template v-slot:activator="{ on: dialogOn }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltipOn }">
                              <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
                                <v-icon color="info">fas fa-question-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Click for more info</span>
                          </v-tooltip>
                        </template>
                        <v-card>
                          <v-card-title>How to get the Google Folder ID</v-card-title>
                          <v-card-text>
                            <ol>
                              <li>Open Google Drive and find the folder where you want the files to be placed (or create a folder if you don't have one already)</li>
                              <li>Open the folder. You should see a URL in the address bar that looks like this: drive.google.com/drive/folders/_________</li>
                              <li>Copy everything after the folders/. This is the folder ID to paste in this text field.</li>
                            </ol>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-select v-model="inputVal.file.fileTypes" :items="fileTypes" label="Acceptable File Types" hint="Optional but recommended" multiple persistent-hint></v-select>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.file.rename" label="Rename uploaded file using template" hint="Optional but recommended" persistent-hint>
                    <template v-slot:append-outer>
                      <v-dialog width="400">
                        <template v-slot:activator="{ on: dialogOn }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltipOn }">
                              <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
                                <v-icon color="info">fas fa-question-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Click for more info</span>
                          </v-tooltip>
                        </template>
                        <v-card>
                          <v-card-title>Rename uploaded file</v-card-title>
                          <v-card-text>
                            <p>The uploaded file can be renamed so that the uploads have a consistent nameing convention. Enter the name that you want for the file, with some optional parameters that will be filled in based on the user or information they entered on the form. If the form requires login and you want to include the person's Banner ID, just enter [[Banner ID]] and this will be replaced by the user's Banner ID (or anything from the list below for logged-in users). You can also use any field in the form by placing the label for that field within double-brackets, like [[First Name]].</p>
                            <p>Options for logged-in users:</p>
                            <ul>
                              <li>Banner ID</li>
                              <li>First Name</li>
                              <li>Last Name</li>
                              <li>Display Name  *(this will put their full name as last, first)</li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <template v-else-if="inputVal.input === 'file-upload'">
                <v-row>
                  <v-col>
                    <v-text-field v-model="inputVal.file.folder" label="Google Drive Folder ID" hint="Required" persistent-hint @change="updateFolderPermission">
                      <template v-slot:append-outer>
                        <v-dialog width="400">
                          <template v-slot:activator="{ on: dialogOn }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltipOn }">
                                <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
                                  <v-icon color="info">fas fa-question-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>Click for more info</span>
                            </v-tooltip>
                          </template>
                          <v-card>
                            <v-card-title>How to get the Google Folder ID</v-card-title>
                            <v-card-text>
                              <ol>
                                <li>Open Google Drive and find the folder where you want the files to be placed (or create a folder if you don't have one already)</li>
                                <li>Open the folder. You should see a URL in the address bar that looks like this: drive.google.com/drive/folders/_________</li>
                                <li>Copy everything after the folders/. This is the folder ID to paste in this text field.</li>
                              </ol>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-select v-model="inputVal.file.fileTypes" :items="fileTypes" label="Acceptable File Types" hint="Optional but recommended" multiple persistent-hint></v-select>
                  </v-col>
                  <v-col>
                    <v-checkbox v-model="inputVal.file.multiple" label="Allow Multiple File Uploads"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="inputVal.file.multiple">
                  <v-col>
                    <v-checkbox v-model="inputVal.file.subfolder" label="Create new subfolder for each submission?" hint="Folder name will include the date and a unique character set for the submission." persistent-hint></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="inputVal.min" label="Minimum Files Required"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="inputVal.max" label="Maximum Files Allowed"></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <v-row v-else-if="inputVal.input === 'signature'">
                <v-col cols="12">
                  <p>The signature pad shows on the page as a button with the completed signature above it. The signature pad where the user enters their signature is loaded within a dialog box which opens when the user clicks the button. This button contains the input label and you can set the color (if the signature is in an error state then it will be red; error state appears if the signature is required and they attempt to submit without including it). You may optionally also include a place for them to enter their name within the dialog as well, which will appear on the form above the button along with their signature.</p>
                </v-col>
                <v-col>
                  <v-select v-model="inputVal.signature.buttonColor" :items="colorOptions" label="Access Button Color" type="text"></v-select>
                </v-col>
                <v-col>
                  <v-text-field v-model="inputVal.signature.title" label="Title (for signature dialog)" type="text" hint="Default: 'Enter Signature Below'" persistent-hint></v-text-field>
                </v-col>
                <v-col>
                  <v-checkbox v-model="inputVal.signature.showEnterName" label="Show box for user to enter name"></v-checkbox>
                </v-col>
              </v-row>
              <template v-else-if="inputVal.input === 'repeatable-block'">
                <v-row>
                  <v-col>
                    <v-checkbox v-model="inputVal.settings.addButton" label="Include button to allow user to add blocks" hide-details></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="inputVal.settings.addButtonText" :disabled="!inputVal.settings.addButton" label="Text for add button" hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="inputVal.settings.min" type="number" label="Minimum blocks required" hide-details></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="inputVal.settings.max" type="number" label="Maximum blocks allowed" hide-details></v-text-field>
                  </v-col>
                  <v-col>
                    <v-checkbox v-model="inputVal.settings.removable" label="Items are removable" hide-details></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h5>Info text at start of repeatable block. Leave blank for no text.</h5>
                    <html-editor v-model="inputVal.info"></html-editor>
                  </v-col>
                </v-row>
              </template>
              <template v-else-if="inputVal.input === 'directory-search'">
                <v-row>
                  <v-col>
                    <v-select v-model="inputVal.dirSearch.personTypes" :items="['Faculty', 'Staff', 'Students']" label="Person Types" hide-details></v-select>
                  </v-col>
                  <v-col>
                    <v-checkbox v-model="inputVal.dirSearch.includePhoto" label="Include photos in search list" hide-details></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-select v-model="inputVal.dirSearch.valueField" :items="[{ text: 'Directory ID', value: '_id' }, { text: 'Username', value: 'username' }, { text: 'Banner ID', value: 'bannerId' }, { text: 'Covenant Email', value: 'email' }]" label="Return data field" hide-details></v-select>
                  </v-col>
                  <v-col>
                    <v-switch v-model="inputVal.dirSearch.nameFormatLastFirst" :label="inputVal.dirSearch.nameFormatLastFirst ? 'Name Format: Last, First' : 'Name Format: First Last'" hide-details></v-switch>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <visibility-rules :input="input" :input-list="inputList" @change="({ ifs }) => inputVal.ifs = ifs"></visibility-rules>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-btn @click="saveChanges" :disabled="!isValid">Save Changes</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="dialogActive = false">Cancel Changes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, reactive, ref, watch } from '@vue/composition-api'
export default {
  components: {
    SelectOptionEditor: () => import('./selectOptionEditor'),
    VisibilityRules: () => import('./visibility-rules.vue'),
    htmlEditor: () => import('@/components/forms/HTMLEditor')
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    inputList: {
      type: Array,
      required: true
    },
    allowRepeatable: {
      type: Boolean,
      default: true
    },
    uniqueEnabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const dialogActive = ref(false)
    function openDialog () {
      dialogActive.value = true
    }
    const selectedTab = ref(0)
    const inputVal = reactive({
      label: '',
      input: '',
      md: 12,
      lg: 12,
      required: false,
      options: '',
      min: null,
      max: null,
      unique: false,
      text: {
        mask: '',
        regex: ''
      },
      email: {
        COV: false
      },
      number: {
        step: null
      },
      textarea: {
        word: false
      },
      select: {
        multiple: false
      },
      switch: {
        appendToLabel: false,
        off: '',
        on: ''
      },
      range: {
        minLabel: '',
        maxLabel: ''
      },
      date: {
        min: null,
        max: null,
        yearFirst: false
      },
      time: {
        min: null,
        max: null
      },
      file: {
        folder: '',
        subFolder: '',
        fileTypes: [],
        rename: ''
      },
      signature: {
        title: '',
        icon: '',
        showEnterName: true,
        buttonColor: ''
      },
      dirSearch: {
        personTypes: [String],
        includePhoto: Boolean,
        valueField: String,
        nameFormatLastFirst: Boolean
      },
      info: '',
      inputs: [],
      ifs: [],
      props: {},
      settings: {
        showLabel: '',
        addButton: '',
        addButtonText: '',
        min: '',
        max: '',
        removable: ''
      }
    })

    watch(() => props.input, () => {
      inputVal.label = props.input.label
      inputVal.input = props.input.input
      inputVal.md = props.input.md
      inputVal.lg = props.input.lg
      inputVal.required = props.input.required || false
      inputVal.options = props.input.options || ''
      inputVal.min = props.input.min || null
      inputVal.max = props.input.max || null
      if ('unique' in props.input && props.uniqueEnabled) {
        inputVal.unique = props.input.unique
      }
      if ('text' in props.input && props.input.input === 'text') {
        inputVal.text = {
          mask: props.input.text.mask || '',
          regex: props.input.text.regex || ''
        }
      } else {
        inputVal.text = {
          mask: '',
          regex: ''
        }
      }
      if ('email' in props.input && props.input.input === 'email') {
        inputVal.email = {
          COV: props.input.email.COV || false
        }
      } else {
        inputVal.email = {
          COV: false
        }
      }
      if ('textarea' in props.input && props.input.input === 'textarea') {
        inputVal.textarea = {
          word: props.input.textarea.word || false
        }
      } else {
        inputVal.textarea = {
          word: false
        }
      }
      if ('select' in props.input && (props.input.input === 'select' || props.input.input === 'autocomplete' || props.input.input === 'combobox')) {
        inputVal.select = {
          multiple: props.input.select.multiple || false
        }
      } else {
        inputVal.select = {
          multiple: false
        }
      }
      if ('switch' in props.input && props.input.input === 'switch') {
        inputVal.switch = {
          appendToLabel: props.input.switch.appendToLabel || false,
          off: props.input.switch.off || '',
          on: props.input.switch.on || ''
        }
      } else {
        inputVal.switch = {
          appendToLabel: false,
          off: '',
          on: ''
        }
      }
      if ('range' in props.input && props.input.input === 'range') {
        inputVal.range = {
          minLabel: props.input.range.minLabel || '',
          maxLabel: props.input.range.maxLabel || ''
        }
      } else {
        inputVal.range = {
          minLabel: '',
          maxLabel: ''
        }
      }
      if ('date' in props.input && props.input.input === 'date') {
        inputVal.date = {
          min: props.input.date.min || null,
          max: props.input.date.max || null,
          yearFirst: props.input.date.yearFirst || false
        }
      } else {
        inputVal.date = {
          min: null,
          max: null,
          yearFirst: false
        }
      }
      if ('time' in props.input && props.input.input === 'time') {
        inputVal.time = {
          min: props.input.time.min || null,
          max: props.input.time.max || null
        }
      } else {
        inputVal.time = {
          min: null,
          max: null
        }
      }
      if ('file' in props.input && ['file', 'file-upload'].includes(props.input.input)) {
        inputVal.file = {
          folder: props.input.file.folder || '',
          subFolder: props.input.file.subFolder || '',
          fileTypes: props.input.file.fileTypes || [],
          rename: props.input.file.rename || '',
          multiple: props.input.file.multiple || false
        }
      } else {
        inputVal.file = {
          folder: '',
          subFolder: '',
          fileTypes: [],
          rename: ''
        }
      }
      if ('signature' in props.input && props.input.input === 'signature') {
        inputVal.signature = {
          title: props.input.signature.title || '',
          icon: props.input.signature.icon || '',
          showEnterName: props.input.signature.showEnterName || false,
          buttonColor: props.input.signature.buttonColor || ''
        }
      } else {
        inputVal.signature = {
          title: '',
          icon: '',
          showEnterName: false,
          buttonColor: ''
        }
      }
      inputVal.info = props.input.info || ''
      inputVal.inputs = props.input.inputs || []
      inputVal.ifs = 'ifs' in props.input && Array.isArray(props.input.ifs) && props.input.ifs.length > 0 ? props.input.ifs : []
      inputVal.props = props.input.props || {}
      if ('settings' in props.input && props.input.input === 'repeatable-block') {
        inputVal.settings = {
          showLabel: props.input.settings.showLabel,
          addButton: props.input.settings.addButton,
          addButtonText: props.input.settings.addButtonText,
          min: props.input.settings.min,
          max: props.input.settings.max,
          removable: props.input.settings.removable
        }
      } else {
        inputVal.settings = {
          showLabel: null,
          addButton: null,
          addButtonText: '',
          min: null,
          max: null,
          removable: null
        }
      }
    })

    function saveChanges () {
      if (typeof (inputVal.lg) === 'object') {
        const { lg, md } = inputVal.lg
        inputVal.md = md
        inputVal.lg = lg
      }
      emit('update', inputVal)
      dialogActive.value = false
    }

    const isValid = computed(() => {
      switch (inputVal.input) {
        case 'select':
        case 'combobox':
        case 'autocomplete':
        case 'radio':
          if (!('options' in inputVal) || inputVal.options == null || inputVal.options === '') return false
          break
        case 'slider':
        case 'rangeSlider':
          if (!('min' in inputVal) || !('max' in inputVal) || inputVal.min == null || inputVal.min === '' || inputVal.max == null || inputVal.max === '') return false
          break
        case 'file':
          if (!('file' in inputVal) || !('folder' in inputVal.file) || inputVal.file.folder === '') return false
          break
      }
      return true
    })

    const inputTypes = computed(() => {
      const arr = [
        { text: 'Text Field', value: 'text' },
        { text: 'Email Address', value: 'email' },
        { text: 'Number', value: 'number' },
        { text: 'Multi-line Text Field', value: 'textarea' },
        { text: 'Select Box', value: 'select' },
        { text: 'Combo Box', value: 'combobox' },
        { text: 'AutoComplete', value: 'autocomplete' },
        { text: 'Radio Group', value: 'radio' },
        { text: 'Checkbox', value: 'checkbox' },
        { text: 'Switch', value: 'switch' },
        { text: 'Slider', value: 'slider' },
        { text: 'Range Slider (Dual Slider)', value: 'rangeSlider' },
        { text: 'Date Picker', value: 'datePicker' },
        { text: 'Time Picker', value: 'timePicker' },
        { text: 'File Upload', value: 'file-upload' },
        { text: 'File Upload (old)', value: 'file' },
        { text: 'Signature Pad', value: 'signature' },
        { text: 'HTML/Text Block', value: 'html-block' },
        { text: 'Church Search', value: 'church-search' },
        { text: 'Directory Search', value: 'directory-search' }
      ]
      if (props.allowRepeatable) arr.push({ text: 'Repeatable Block', value: 'repeatable-block' })
      return arr
    })

    const widthOptions = ref([
      { text: 'Full', md: 12, lg: 12 },
      { text: 'Half (full on small)', md: 6, lg: 6 },
      { text: 'Third (full on small)', md: 4, lg: 4 },
      { text: 'Quarter (half on medium screens, full on small)', md: 6, lg: 3 }
    ])

    const optionListSearch = ref('')
    const selectOptions = ref([])
    watch(optionListSearch, () => {
      let query = { $or: [{ 'visibleRoles.0': { $exists: false } }, { visibleRoles: { $in: root.$store.state.user.roles } }], active: { $in: [true, null] }, $limit: 20, $sort: { name: 1 } }
      if (optionListSearch.value != null) {
        query.name = { $regex: optionListSearch.value, $options: 'i' }
      }
      root.$feathers.service('forms/select-options').find({ query }).then(({ data }) => {
        selectOptions.value = data
        if (inputVal.options) {
          root.$feathers.service('forms/select-options').find({ query: { _id: inputVal.options } }).then(({ data }) => {
            if (data.length > 0) selectOptions.value.push(data[0])
          })
        }
      })
    })

    const dateOptions = ref([
      { text: '-1 Week', value: 'today.-7' },
      { text: '-2 days', value: 'today.-2' },
      { text: 'Yesterday', value: 'today.-1' },
      { text: 'Today', value: 'today' },
      { text: 'Tomorrow', value: 'today.+1' },
      { text: '+2 Days', value: 'today.+2' },
      { text: '+1 Week', value: 'today.+7' },
      { text: 'Start of last month', value: 'month.last.start' },
      { text: 'Start of current month', value: 'month.current.start' },
      { text: 'Start of next month', value: 'month.next.start' },
      { text: 'Start of last trad term', value: 'term.last.start' },
      { text: 'Start of current trad term', value: 'term.current.start' },
      { text: 'Start of next trad term', value: 'term.next.start' },
      { text: 'Start of last calendar year', value: 'year.last.start' },
      { text: 'Start of current calendar year', value: 'year.current.start' },
      { text: 'Start of next calendar year', value: 'year.next.start' },
      { text: 'Start of last fiscal year', value: 'fy.last.start' },
      { text: 'Start of current fiscal year', value: 'fy.current.start' },
      { text: 'Start of next fiscal year', value: 'fy.next.start' },
      { text: 'End of last month', value: 'month.last.end' },
      { text: 'End of current month', value: 'month.current.end' },
      { text: 'End of next month', value: 'month.next.end' },
      { text: 'End of last trad term', value: 'term.last.end' },
      { text: 'End of current trad term', value: 'term.current.end' },
      { text: 'End of next trad term', value: 'term.next.end' },
      { text: 'End of last calendar year', value: 'year.last.end' },
      { text: 'End of current calendar year', value: 'year.current.end' },
      { text: 'End of next calendar year', value: 'year.next.end' },
      { text: 'End of last fiscal year', value: 'fy.last.end' },
      { text: 'End of current fiscal year', value: 'fy.current.end' },
      { text: 'End of next fiscal year', value: 'fy.next.end' }
    ])
    const fileTypes = ref([
      { text: 'PDF', value: '.pdf,application/pdf' },
      { text: 'Word Document', value: '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
      { text: 'Excel Spreadsheet', value: '.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      { text: 'Image', value: 'image/*' },
      { text: 'Audio', value: 'audio/*' },
      { text: 'Video', value: 'video/*' }
    ])
    const colorOptions = ref([
      { text: 'default', value: '' },
      { text: 'white', value: 'white black--text' },
      { text: 'dark blue', value: 'indigo darken-4 white--text' },
      { text: 'blue', value: 'blue white--text' },
      { text: 'light blue', value: 'blue lighten-3 black--text' },
      { text: 'green', value: 'green darken-2 white--text' },
      { text: 'blue grey', value: 'blue-grey darken-1 white--text' }
    ])

    async function updateFolderPermission (val) {
      if (val === '') return
      if (val.search('/folders/') > 0) {
        val = val.substring(val.lastIndexOf('/') + 1)
        inputVal.file.folder = val
      }
      const user = root.$store.state.user.spoof || root.$store.state.user
      const formId = root.$route.params.id
      const { data } = await root.$feathers.service('system/drive-upload-perm').find({ folderId: val })
      if (data.length === 0) {
        await root.$feathers.service('system/drive-upload-perm').create({ folderId: val, user: user.username, forms: [formId] })
      } else {
        const row = data[0]
        if (!('forms' in row) || !row.forms.includes(formId)) {
          await root.$feathers.service('system/drive-upload-perm').patch(row._id, { $push: { forms: formId } })
        }
      }
    }

    return {
      dialogActive,
      openDialog,
      selectedTab,
      inputVal,
      saveChanges,
      isValid,
      inputTypes,
      widthOptions,
      optionListSearch,
      selectOptions,
      dateOptions,
      fileTypes,
      colorOptions,
      updateFolderPermission
    }
  }
}
</script>
